.section {
    margin: 3em 0 0 0;
    background-color: #303030;
    /* Dark grey background */
    color: #ffffff;
    /* White text color */
    padding: 60px 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content {
    max-width: 600px;
    margin: 0 auto;
}

.heading {
    font-size: 32px;
    color: #ffffff;
    /* White font color */
    margin-bottom: 20px;
    font-family: 'Trebuchet MS', sans-serif;
}

.text {
    font-size: 18px;
    color: #ffffff;
    /* White font color */
    margin-bottom: 30px;
    line-height: 1.5;
    font-family: 'Georgia', serif;
}

.button {
    background-color: #FFCD02;
    /* Bright yellow button background */
    border: none;
    color: #ffffff;
    /* White button text color */
    height: 40px;
    text-transform: uppercase;
    font-family: Verdana, Geneva, sans-serif;
    letter-spacing: 1px;
    line-height: 38px;
    padding: 0 28px;
    border-radius: 32px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.3s ease;
    animation: bounce 5s infinite;
    /* Add bounce animation */
}

.button:hover {
    background-color: #79796D;
    /* Grey hover effect */
}


/* Bounce animation */

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}