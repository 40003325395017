.bird-control-content {
    margin-top: 3em;
    padding: 3em;
}

.bird-control-content h1 {
    font-family: 'Montserrat' !important;
    color: var(--p-blue) !important;
}

.bird-control-content h2 {
    font-family: 'Montserrat' !important;
    color: #fff !important;
}

.bird-control-content p {
    font-weight: 300 !important;
}

.bird-control-content ul {
    font-weight: 300 !important;
}

@media screen and (max-width: 960px) {
    .bird-control-content {
        padding: 5em 1em 0 1em;
        text-align: center;
    }
    .bird-control-content h1 {
        font-size: 32px;
    }
    .bird-control-content h1 {
        color: var(--p-blue) !important;
        font-family: 'Montserrat' !important;
    }
    .bird-control-content h2 {
        color: var(--p-blue) !important;
        font-family: 'Montserrat' !important;
    }
}