/* ==============================FAQS=========================== */

.faqs {
    margin-top: 6em;
    background: var(--p-blue);
    width: 99vw;
}

.faqs_container {
    margin: 0;
    padding: 3em;
}

.faqs_header {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    padding: 4em;
    color: #fff;
}

.faqs_header h1 {
    font-family: 'Montserrat' !important;
}

.faqs_wrapper {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 1em;
    width: 92vw;
}

.faq {
    padding: 2rem;
    cursor: pointer;
    color: #ddd;
    border-bottom: solid 1px #ddd;
    width: 100%;
}

.faq h4 {
    font-family: 'Montserrat' !important;
}

.faq div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq_icon {
    background: transparent;
    color: var(--color-gray-100);
    font-size: 1.5rem;
}

.faq p {
    margin-top: 1.5rem;
}

@media screen and (max-width: 960px) {
    .faqs {
        width: 100vw;
    }
    .faqs_container {
        margin: 0;
        padding: 0;
    }
    .faqs_header {
        padding: 2em 0 0 2em;
    }
    .faqs_wrapper {
        width: 98vw;
        text-align: center;
    }
}