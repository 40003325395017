.services {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
}

.automative-desc p {
    width: 50vw !important;
}

.commercial-pest-control {
    width: 50vw;
}

.commercial-pest-control-content {
    margin-top: 3em;
    padding: 3em;
    font-family: 'Montserrat';
}

.commercial-pest-control-content h1,
h2,
h3,
b {
    font-family: 'Montserrat' !important;
    color: var(--p-blue);
}

.commercial-pest-control-content p {
    font-weight: 300 !important;
}

@media screen and (max-width: 960px) {
    .commercial-pest-control-content {
        padding: 5em 1em 0 1em;
        text-align: center;
    }
    .commercial-pest-control-content h1 {
        font-size: 32px;
    }
}