.residential-pest-control-content {
    margin-top: 3em;
    padding: 3em;
}

.residential-pest-control-content h1,
h2,
h3,
b {
    font-family: 'Montserrat' !important;
    color: var(--p-blue);
}

.residential-pest-control-content p {
    font-weight: 300 !important;
    width: 70vw;
}

.residential-pest-control-content ul {
    font-weight: 300 !important;
}

@media screen and (max-width: 960px) {
    .residential-pest-control-content {
        padding: 5em 1em 0 1em;
        text-align: center;
    }
}