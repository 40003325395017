.benefits-main {
    background: #242222;
    padding: 3em;
}

.benefits-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    font-size: 20px;
}

.benefits-cont-1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    padding-bottom: 3.6em;
    border-bottom: solid 0.5px #fff;
}

.b-cont-1a,
.b-cont-1b {
    padding: 1em;
}

.b-cont-1a p {
    padding: 0 !important;
    margin: 0 !important;
    color: var(--p-blue);
}

.b-cont-1a h2 {
    color: #fff;
    margin-top: 3px;
}

.b-cont-1b p {
    font-weight: lighter;
}

.cont-1-dec-border {
    width: 6em;
    border: solid 2px var(--p-blue);
    margin-top: 3em;
}

.benefits-cont-2 {
    padding: 4em 3em 1em 3em;
    width: 99vw;
}


/* Slick slider carousel width */

.slick-list {
    width: 99vw !important;
    padding: 0 !important;
}

.slick-slider p {
    text-align: center;
    font-family: 'Montserrat';
}

.benefits-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 24vw;
}

.benefit-icon {
    font-size: 63px;
    padding: 10px;
    border: 0.5px solid #fff;
    border-radius: 50%;
    height: 2em;
    width: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--p-blue);
}

.benefits-card p {
    font-size: 17px;
    font-weight: lighter !important;
}

.benefits-cont-2>.slick-next {
    border: solid red 5px !important;
    z-index: 999;
    position: relative;
    right: 0;
    bottom: 0;
    width: 30% !important;
    height: auto;
}