.team-cont {
    display: flex;
    flex-direction: column;
    margin: 3em 3em 2em 3em;
    grid-gap: 1em;
}

.team-card {
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    margin-bottom: 2em;
}

.team-card-img {
    width: 100%;
}

.team-card-img img {
    width: 21vw;
}

.team-member-details {
    padding: 3px;
}

.team-member-details h4 {
    font-family: 'Baskervville', serif !important;
    color: var(--p-blue);
}