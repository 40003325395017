.fumigation-services-content {
    margin-top: 3em;
    padding: 3em;
}

.btn-fumigation {
    margin: 1em !important;
    display: inline-block;
}

.fumigation-services-content h1,
h2,
h3,
b {
    font-family: 'Montserrat' !important;
    color: var(--p-blue);
}

.fumigation-services-content p {
    font-weight: 300 !important;
}

@media screen and (max-width: 960px) {
    .fumigation-services-content {
        padding: 5em 1em 0 1em;
        text-align: center;
    }
    .btn-fumigation {
        margin: 0 !important;
        width: 56vw !important;
    }
}