.consultants-cont {
    margin: 3em 3em 0 3em;
}

.consultants-cont h3 {
    color: var(--p-blue);
    font-size: 25px !important;
    font-family: 'Baskervville', serif !important;
}

.consultants-cont p {
    font-weight: 300;
    font-size: 16px !important;
}

@media screen and (max-width:960px) {
    .consultants-cont {
        margin: 1em 1em 0 1em !important;
        text-align: center !important;
    }
    .consultant-card {
        flex-direction: column;
        margin: 6em 0.5em 2em 0.5em;
    }
    .consultant-card {
        flex-direction: column;
    }
    .consultant-card-img img {
        width: 90vw;
    }
    .consultant-member-details {
        text-align: center !important;
        width: 90vw !important;
    }
}