.navbar {
    height: 6.8rem;
    width: 100vw;
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: 'Montserrat';
}

.nav-bg {
    background: #242222;
}

.logo {
    justify-self: center;
    cursor: pointer;
    color: #fff;
    width: 15%;
}

.logo img {
    padding-top: 0;
    width: 84% !important;
}

.logo h1 {
    font-family: 'Montserrat';
}

.drop-down-arrow {
    font-size: 1.6rem;
    position: relative;
    top: 7.5px;
    right: 4px;
}

.close-open-btn {
    color: var(--p-blue) !important;
}

.nav-menu {
    display: flex;
    flex-direction: row;
    grid-gap: 3px;
    list-style: none;
    text-align: center;
    width: 80vw;
    justify-content: end;
    margin-right: 2rem;
    font-family: 'Montserrat';
}

.nav-item {
    display: flex;
    align-items: center;
    height: 80px;
    font-family: 'Montserrat';
    font-size: 17px;
}

.services-nav-item {
    position: relative;
    bottom: 1.5px;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-family: 'Montserrat';
    font-size: 16px;
    padding: 0 1em 0 13px;
}

.commercial-link {
    position: relative;
    bottom: 4px !important;
}

.nav-links:hover {
    color: var(--p-blue) !important;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: var(--p-blue);
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .logo img {
        padding-top: 0;
        width: 142% !important;
    }
    .navbar {
        justify-content: space-between;
        align-items: center;
        padding: 1em;
        height: 4.8em;
    }
    a.nav-links {
        background-color: black !important;
    }
    span.burgerCloseMenu {
        display: inline-block;
        position: relative;
        top: 10px;
    }
    .NavbarItems {
        position: relative;
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        height: 22em;
        top: 14em;
    }
    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }
    .nav-links:hover {
        border-radius: 0;
    }
    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }
    .menu-icon {
        display: block;
        position: absolute;
        top: -10px;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
    .fa-times {
        color: #fff;
        font-size: 2rem;
    }
    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #1888ff;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }
    .nav-links-mobile:hover {
        background: #fff;
        color: #1888ff;
        transition: 250ms;
    }
    .dropdown-menu {
        display: block;
    }
    .dropdown-menu-two {
        display: block;
    }
}