.floating-button {
    background-color: #FFCD02;
    /* Bright yellow background */
    color: #ffffff;
    /* White text color */
    height: 50px;
    width: 150px;
    text-align: center;
    line-height: 50px;
    font-family: Verdana, Geneva, sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 25px;
    position: fixed;
    bottom: 70px;
    right: 20px;
    z-index: 1000;
    /* Ensure the button is above other content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.floating-button:hover {
    background-color: #79796D;
    /* Grey hover effect */
    transform: translateY(-5px);
    /* Slightly lift the button on hover */
}