@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,800&display=swap');
h1,
h2,
h3,
h4,
h5 {
    font-family: 'Monserrat' !important;
}


/* HEADER SECTION */

.homepage-header {
    width: 100vw;
    height: calc(100vh - 5rem);
    display: grid;
    place-items: center;
    margin-top: 5rem;
    margin-right: 0;
    background: rgb(219, 228, 230);
    background: linear-gradient(90deg, rgba(37, 39, 39, 0.662) 0%, rgba(212, 218, 221, 0.958420868347339) 51%, rgba(205, 208, 212, 0.989233193277311) 100%);
    object-fit: contain;
    z-index: 1;
}

.homepage-header-texture-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../images/homepage-header/pattern4.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
}

.home-page-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: flex-end;
    gap: 3rem;
    /* padding: 7em; */
}

.homepage-header-left {
    position: relative;
    right: 6rem;
    padding-left: 3em;
    padding-bottom: 3em;
    width: 46vw;
    position: relative;
    top: 1em;
    left: 5px;
}

.homepage-header-left h1 {
    position: absolute;
    top: 25px;
    font-family: 'Montserrat' !important;
}

.homepage-header-title {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 500;
    color: var(--p-green);
    margin-bottom: 1rem;
    text-transform: uppercase;
    word-spacing: 5px;
}

.homepage-header-text {
    font-family: 'Montserrat';
    font-size: 60px;
    font-weight: 1000;
    color: var(--p-blue);
    margin-bottom: 1rem;
    line-height: 1.2;
    letter-spacing: 0px;
}

.home-header-rotating-text {
    font-family: 'Montserrat';
    font-size: 60px;
    font-weight: 1000;
    color: var(--p-green);
    margin-bottom: 1rem;
    line-height: 1.2;
    letter-spacing: 0px;
    display: inline-block;
    position: relative;
    animation: rotating-text 5s infinite;
}

.home-header-description {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 500;
    color: var(--p-blue);
    margin-bottom: 1rem;
    line-height: 1.2;
    letter-spacing: 1px;
    padding-bottom: 2.5rem;
}

.homepage-header-btn {
    font-family: 'Montserrat';
    margin-top: 2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: var(--transition);
}

.homepage-header-btn:hover {
    background: #fff;
    color: var(--p-blue);
    border: 1px solid var(--p-blue);
    transition: var(--transition);
}

.homepage-header-right {
    display: flex;
    align-self: self-end;
}

.home-page-header-image-container {
    position: relative;
    width: 110%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-page-header-image-cont img {
    width: 80%;
}


/* HOME HERO React awesome slider */

.hero-section-cont {
    display: flex;
    flex-direction: column;
}

.awssld__wrapper {
    height: calc(100vh) !important;
}

.awssld__wrapper>img {
    height: calc(100vh) !important;
}

.hero-image {
    z-index: -999 !important;
}

nav.awssld__bullets {
    display: none !important;
}

.hero-text {
    z-index: 999;
    position: absolute;
    top: 30%;
    left: 10%;
    width: max-content;
}

.hero-text-detail {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.hero-text-detail>h1 {
    font-size: 80px;
    font-weight: 800;
    font-family: 'Baskervville', serif;
    color: #fff;
}

.hero-text-detail>h3 {
    font-size: 40px;
    font-weight: 500;
    color: #fff !important;
}

.hero-text-detail>p {
    color: #fff !important;
    font-weight: 300 !important;
    font-size: 20px;
    width: 40vw;
}

.hero-btn {
    z-index: 999 !important;
    position: absolute;
    top: 70vh;
    left: 10%;
    z-index: 999;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}


/* CTA SECTION */

.cta-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: flex-start;
    width: 100vw;
    color: white;
    font-size: 18px;
    font-family: 'Montserrat';
}

.container.cta-container h2,
h3 {
    font-family: 'Montserrat'!important;
    color: var(--p-blue) !important;
}

.cta-text-l {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-end;
    text-align: right;
    font-family: 'Montserrat';
    padding: 3em 3em 3em 0;
    width: 30vw;
    margin: 2em 0 2em 0;
}

.cta-text-l h2 {
    font-size: 35px !important;
    font-weight: 600 !important;
    color: var(--p-blue) !important;
}

.cta-text-r {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
    text-align: left;
    padding: 3em 2em 3em 1em;
    font-family: 'Montserrat';
    width: 100vw;
}

.cta-text-r p {
    text-align: center;
}

.value {
    padding: 0em;
    background: none !important;
}

.value-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-family: 'Montserrat';
}

.value-icon {
    color: var(--p-blue) !important;
    font-size: 45px;
    font-weight: 300 !important;
}

.value-cont p {
    font-weight: 300 !important;
    font-size: 17px !important;
}

.cta-text-r p {
    padding: 25px 0;
    color: #000;
    font-size: 34px;
    font-weight: 300 !important;
}

.cta-text-r h3 {
    color: var(--p-blue) !important;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 400;
}

.cta-text-r h3 {
    color: var(--p-blue) !important;
    font-family: 'Montserrat', sans-serif !important;
}


/* HOME INTRO */

.home-about-sect {
    text-align: center;
    padding: 3em;
}

.home-about-sect h4 {
    font-size: 34px;
    font-weight: 600;
    font-family: 'Baskervville', serif !important;
    color: var(--p-blue);
}

.home-about-sect p {
    font-size: 17px;
}


/* ABOUT SECTION */

.homeAbout {
    margin-top: 16em;
}

h2 .about-fd-title {
    font-family: 'Montserrat' !important;
    display: none;
}

.home-about-container {
    display: grid;
    width: 100vw;
    grid-template-columns: repeat(2, 1fr);
    color: black;
    font-size: 18px;
}

.home-abt-text-l {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-content: flex-end; */
    /* text-align: right; */
    font-family: 'Montserrat' !important;
    padding: 3em;
    z-index: -1;
}

.home-abt-img-r {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-content: flex-start;
    text-align: left; */
    padding: 0;
    filter: drop-shadow(0px 4px 4px rgba(129, 129, 129, 0.397));
    z-index: 1;
    position: relative;
    top: -5em;
    height: 100%;
    width: 100%;
}

.home-abt-img-r img {
    width: 85%;
    border-radius: 7px;
    z-index: -1;
}

.home-abt-text-l h5 {
    font-family: 'Montserrat' !important;
    padding: 10px 0;
    color: var(--p-blue);
    word-spacing: 0px;
    letter-spacing: 5px;
}

.home-abt-text-l h2 {
    padding: 5px 0;
    font-weight: bold;
    font-size: 40px;
    color: var(--p-blue);
    font-family: 'Montserrat' !important;
}

.home-abt-text-l p {
    padding: 6px 0;
    color: black;
    font-family: 'Montserrat';
    font-weight: 300;
}

.quote-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background: white;
    color: var(--p-blue);
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    width: 60%;
    margin: 0 auto;
    position: absolute;
    left: 30%;
    top: 70%;
    z-index: 1;
    border-radius: 5px;
    border-top: solid 5px var(--p-green);
    filter: drop-shadow(0px 4px 4px rgba(129, 129, 129, 0.274));
}


/* SERVICES HEADER */

.cta_cont_practice_area {
    padding: 0;
}

.value_practice_area p {
    font-size: 17px;
    font-weight: 300 !important;
    color: #000;
}


/* SERVICES SECTION */

.services-carousel-sect {
    margin-top: 3em;
}

.home-services-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2em 1em 2em 1em;
}

.home-services-img {
    width: 100%;
}

.home-services-img img {
    width: 100%;
    height: 50vh;
    filter: grayscale(100%);
}

.home-services-cont-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em;
    padding: 0.5em;
}

.home-services-cont-text h4,
p {
    font-family: 'Montserrat' !important;
}

.home-services-cont-text h4 {
    color: #000;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
}

.home-services-cont-text p {
    text-align: center;
    margin-bottom: 2em;
    font-size: 17px;
    font-weight: 300 !important;
}


/* APPROACH */

.approach {
    margin-top: 24em;
}

.approach-container {
    display: grid;
    width: 100vw;
    grid-template-columns: repeat(2, 1fr);
    color: black;
    font-size: 18px;
    align-items: center !important;
    position: relative;
    height: 48em;
}

.approach-text-l {
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: left;
    font-family: 'Montserrat';
    padding: 4em 3em 9em 3em;
    z-index: -1;
    background: rgba(175, 175, 175, 0.662);
    width: 50%;
    height: auto;
    position: absolute;
    right: 0 !important;
    top: -6em;
}

.approach-img-r {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    padding: 0 0 0 3em;
    filter: drop-shadow(0px 4px 4px rgba(129, 129, 129, 0.397));
    z-index: 1;
    position: relative;
    top: -5em;
    height: 70%;
    width: 100%
}

.approach-img-r img {
    width: 100%;
    object-fit: contain;
    border-radius: 7px;
    z-index: -1;
}

.approach-text-l h5 {
    font-family: 'Montserrat';
    padding: 10px 0;
    color: var(--p-green);
    word-spacing: 0px;
    letter-spacing: 5px;
}

.approach-text-l h2 {
    padding: 5px 0;
    font-weight: bold;
    font-size: 40px;
    font-family: 'Montserrat';
    color: var(--p-blue);
}

.approach-text-l p {
    padding: 6px 0;
    color: black;
    font-family: 'Montserrat';
    font-weight: 300;
}


/* FLIP CARD */

.flip-card-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    position: relative;
    bottom: 10em;
    right: 3em;
    gap: 10px;
}


/* Home Services Carousel */

.home_services {
    margin-top: 1em;
}

.home_services_container {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.home_services_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.home_services_header h3,
h2,
p {
    font-family: 'Montserrat';
    margin: 1em 0 0 0;
}

.home_services_header h2 {
    font-weight: 600;
    color: var(--p-blue);
    font-family: 'Montserrat' !important;
}

.home_services_header h3 {
    font-weight: 600;
    color: var(--p-blue) !important;
    letter-spacing: 5px;
    font-family: 'Montserrat' !important;
}

.home_services_header p {
    font-weight: 300;
}

.home_services_carousel {
    padding: 0 3em 0 3em;
    width: 100vw;
    position: relative;
    bottom: 0;
}

.home_services_carousel_item {
    width: 100%;
}

.home_services_carousel_items {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    margin-top: 4em;
    filter: drop-shadow(0px 4px 4px rgba(129, 129, 129, 0.397));
}

.home_services_carousel_items img {
    width: 90%;
    border-radius: 7px 7px 0 0;
    object-fit: cover;
    height: 40vh;
    filter: grayscale(60%);
}

.home_services_details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3em;
}

.home_services_details h3,
p {
    font-family: 'Montserrat';
}

.home_services_details h3 {
    color: var(--p-blue);
    font-family: 'Montserrat' !important;
}

.home_services_details p {
    padding-bottom: 2em;
    color: black;
    font-weight: 300;
}

.home_services_btn {
    padding: 10px 20px;
}

.home_services_btn:hover {
    background: #fff;
    color: var(--p-blue);
    border: 1px solid var(--p-blue);
}


/* ==============================Car simulator=========================== */

.testimonials {
    width: 50vw;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.testimonials_head {
    justify-content: center;
}

.card.testimonial {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
}

.testimonial_avatar {
    width: 30vw;
    height: auto;
}

.testimonial_desc {
    padding: 1em 0 0 1em;
    width: 30vw;
}

.testimonial_quote {
    font-style: inherit;
}

.card small .testimonial_title {
    margin-top: 0.3rem;
}

.testimonial_btn_container {
    width: fit-content;
    margin: 2.5rem auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.testimonial_btn {
    background: transparent;
    cursor: pointer;
}

.testimonial_btn svg {
    fill: var(--color-golden-yellow);
    font-size: 1.8rem;
    width: 3rem;
    height: 3rem;
}


/* saftey assurance */

.saftey {
    margin-top: 10em;
    display: grid;
    place-items: center;
    width: 100%;
}

.saftey_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: #F5F7F2;
    width: 95%;
    height: 25em;
    border-radius: 7px;
}

.saftey_header {
    padding: 0 2em 0 2em;
}

.saftey_header h1,
p {
    font-family: 'Montserrat';
}

.saftey_header h1 {
    color: var(--p-blue);
}

.saftey_header p {
    color: var(--p-blue);
    font-weight: 400;
}

.saftery_image {
    display: flex;
    align-self: self-end;
}

.saftey_image img {
    width: 130%;
    filter: grayscale(0.5);
    position: absolute;
    top: 0;
    right: 0;
}


/* WHY US */

.whyus {
    margin-top: 10em;
    display: grid;
    place-items: center;
    width: 100%;
}

.whyus__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2em;
    background: var(--p-blue);
    color: #fff;
    font-family: 'Montserrat';
    grid-gap: 2em;
}

.whyus__container h1,
h2,
h3,
h4 {
    font-family: 'Montserrat' !important;
}

.why_r_det h2 {
    font-family: 'Montserrat' !important;
}

.why_r_icon h2 {
    color: #fff !important;
}

.whyus_l h1,
h3,
p {
    font-family: 'Montserrat';
}

.whyus_l h3 {
    color: var(--p-green);
}

.whyus_l p {
    padding-bottom: 2em;
}

.whyus_r {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1em;
}

.why_det {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.why_r_det {
    padding: 4em;
    border: solid 0.5px #ddd;
    border-radius: 7px;
    width: 25vw;
    height: 60vh;
}

.why_r_det .why_r_icon {
    font-size: 51px !important;
}

.why_r_det h2 {
    color: var(--p-green);
}

.whyus_btn {
    background: var(--p-blue);
    color: #fff;
    border: solid 1px #fff;
}

.whyus_btn:hover {
    background: #fff;
    color: var(--p-blue);
    border: solid 1px var(--p-blue);
}

.why_r_det h2 {
    color: #fff !important;
}


/* Testimonials */

.testimonials {
    margin-top: 7em;
    display: grid;
    place-items: center;
    width: 100%;
}

.testimonials_cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2em;
    color: var(--p-blue);
    font-family: 'Montserrat';
    grid-gap: 2em;
}

.testimonial_l {
    width: auto;
}

.testimonial_l img {
    width: 40vw;
    height: auto;
    border-radius: 7px;
    object-fit: fill;
    filter: drop-shadow(0px 0px 0.75px rgba(0, 0, 0, 0.25));
}

.testimonial_r {
    width: auto;
}

.testimonials_header h1 {
    font-family: 'Montserrat' !important;
    color: var(--p-blue);
    font-weight: 600;
    letter-spacing: 1px;
    padding: 20px 0 5px 0;
    font-size: 41px;
}

.testimonials_header h3 {
    font-family: 'Montserrat' !important;
}

.testimonials_header p {
    font-family: 'Montserrat';
    font-weight: 300;
    color: #000;
}

.testimonial {
    margin-top: 2em;
    padding: 1em;
    background: #f7f7f7;
    border-top: 4px solid var(--p-green);
    border-radius: 7px;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.397));
    color: var(--p-blue);
    font-family: 'Montserrat';
    font-weight: 200;
    width: auto;
}

.testimonial_desc {
    font-weight: 400;
}

.quote_left,
.quote_right {
    color: var(--p-green);
    font-size: 1em;
    margin: 0 0.5em;
}

.testimonial_img img {
    width: 4vw;
    border-radius: 100%;
}

.testimonial_avatar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em;
    font-family: 'Montserrat';
    grid: 4em;
}

.testimonial_bio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em;
    grid: 4em;
    font-family: 'Montserrat';
}

h4.testimonial_title {
    font-family: 'Montserrat';
    font-weight: 400;
}

.testimonial_arrows {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em;
    gap: 4em;
    font-family: 'Montserrat';
}

.testimonial_arrow {
    background: none !important;
    border: none !important;
    color: var(--p-green) !important;
    font-weight: 800;
    font-size: 16px;
}


/* Contacts CTA */

.contact_cta {
    margin-top: 5em !important;
}

.contact_cta_header h4 {
    font-family: 'Montserrat' !important;
    color: var(--p-blue);
    letter-spacing: 5px;
}

.contact_cta_header h2 {
    font-family: 'Baskervville', serif !important;
    color: var(--p-blue) !important;
}

.contact_cta_header p {
    font-weight: 300;
    color: #000;
}

.contact_cta_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--p-blue);
    font-family: 'Montserrat';
    grid-gap: 2em;
}

.contact_cta_cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--p-blue);
    font-family: 'Montserrat';
    grid-gap: 2em;
    margin-top: 3em;
}

.contact_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--p-blue);
    font-family: 'Montserrat';
    grid-gap: 2em;
    background: #e0e0e0;
    border-radius: 7px;
    padding: 1em;
    width: 30vw;
    height: 50vh;
    border-top: 5px solid var(--p-green);
}

.contact_card h3 {
    font-weight: bold;
    color: var(--p-blue);
    font-size: 25px;
    font-family: 'Montserrat' !important;
}

.contact_card p {
    font-weight: 300;
    color: #000;
}

.contact_cta_icon {
    font-weight: lighter !important;
    font-size: 40px !important;
    color: var(--p-blue);
}

.contact-cta-btn:hover {
    background: #fff;
    color: var(--p-blue);
    border: 1px solid var(--p-blue);
}


/* media queries */

@media screen and (max-width: 960px) {
    html,
    body {
        overflow-x: hidden !important;
    }
    [data-aos] {
        opacity: 1 !important;
        transform: none !important;
    }
    .container {
        width: 100vw !important;
    }
    /* =====================================================Hero */
    .home-hero {
        height: 68hv !important;
    }
    img.hero-image {
        height: 120vh !important;
    }
    .awssld__wrapper {
        height: calc(68vh) !important;
    }
    .awssld__wrapper {
        height: calc(68vh) !important;
    }
    .hero-text-detail p {
        display: none !important;
    }
    .hero-text-detail h1,
    h3 {
        font-size: 30px !important;
    }
    .image-overlay {
        height: min-content !important;
    }
    a.btn.sm.cta-btn-hero {
        position: relative !important;
        bottom: 16em !important;
    }
    .awssld__content {
        height: 86vh;
    }
    /* =================================================CTA SECTION */
    .cta-container {
        grid-template-columns: 1fr !important;
    }
    .cta-text-r {
        flex-direction: column !important;
    }
    .cta-text-l,
    .cta-text-r {
        width: 100vw !important;
        padding: 0.5em !important;
    }
    .home-about-sect {
        padding-top: 1em;
    }
    .value {
        padding: 0.5em !important;
    }
    .home-about-sect {
        padding: 1em !important;
    }
    /* ===============================================Practice Areas */
    .cta_cont_practice_area .cta-text-l {
        padding: 0.5em !important;
        margin: 0 !important;
    }
    .services-carousel-sect {
        margin-top: 0em !important;
    }
    .home-services-card {
        margin: 2em 1em 0 1em !important;
    }
    /* =================================================Home Page */
    .homepage-header {
        height: 69vh;
    }
    .homepage-header-texture-bg {
        display: none !important;
    }
    .homepage-header-left.aos-init.aos-animate {
        width: 99vw;
        position: relative;
        top: 3em;
        left: -4em;
    }
    .homepage-header-text {
        font-size: 44px;
    }
    .home-header-rotating-text {
        font-size: 44px;
    }
    .homepage-header-left h1 {
        position: absolute;
        top: -3px;
        width: 44vw;
    }
    .home-page-container {
        grid-template-columns: 1fr;
        justify-items: flex-start;
        padding: 2em;
    }
    .home-header-description {
        display: none;
    }
    .home-page-header-image-cont img {
        display: none;
    }
    .homepage-header-btn {
        display: none;
    }
    .cta-container {
        grid-template-columns: 1fr;
    }
    .cta-text-l,
    .cta-text-r {
        align-items: center;
        text-align: center !important;
        padding: 1.5em;
    }
    .cta-text-r {
        padding-top: 3em;
        text-align: center;
    }
    .contact_card p {
        padding: 0 16px;
    }
    .homeAbout {
        margin-top: 0em !important;
    }
    .home-about-container {
        grid-template-columns: 1fr !important;
        margin-top: 3em !important;
        text-align: center !important;
    }
    .home_services_header {
        margin-top: 0 !important;
    }
    .home-abt-text-l {
        padding: 1em !important;
    }
    .home-abt-img-r img {
        display: none !important;
    }
    .quote-cont {
        display: none;
    }
    .home_services_carousel {
        padding: 0 0em 0 0em;
    }
    .home_services_two_container {
        margin: 13.5px;
    }
    .fumigation {
        flex-direction: column !important;
    }
    .fumigation_content {
        padding: 0em !important;
        padding-top: 3em !important;
        padding-bottom: 3em !important;
        text-align: center !important;
    }
    .fumigation_img img {
        border-radius: 0px !important;
    }
    .btn-fumigation {
        display: inline-block;
        margin: 2em 0 0 0;
    }
    .pest_control {
        flex-direction: column !important;
        align-items: flex-end !important;
        width: 93vw !important;
    }
    .bird-control-cont {
        width: 95vw !important;
        text-align: center !important;
    }
    .consultancy-cont {
        width: 95vw !important;
        text-align: center !important;
    }
    .container.saftey_container {
        flex-direction: column !important;
        margin-top: 10em !important;
        padding: 0 !important;
        align-items: center;
        justify-content: center !important;
        text-align: center !important;
    }
    .saftey_header {
        text-align: center !important;
        font-size: 16px !important;
        width: 100vw !important;
    }
    .saftey_header h1 {
        font-size: 28px !important;
        text-align: center !important;
    }
    .saftery_image img {
        display: none !important;
    }
    .whyus {
        display: grid !important;
        grid-template-columns: 1fr !important;
    }
    .whyus__container {
        flex-direction: column;
        width: 96vw;
    }
    .whyus_r {
        grid-template-columns: 1fr;
    }
    .why_r_det {
        width: 90vw;
        padding: 2.7em;
        height: fit-content;
    }
    .testimonials {
        display: grid;
        grid-template-columns: 1fr !important;
    }
    .testimonial_l {
        display: none;
    }
    .testimonials_cont {
        padding: 0 1em 0 1em;
    }
    .testimonial_avatar {
        flex-direction: column !important;
    }
    .testimonial_img img {
        width: 26vw !important;
    }
    /* .contact_cta_icon_one {
        font-size: 19px !important;
    } */
    .contact_cta_header p {
        padding: 0 12px;
    }
    .contact_cta_cards {
        flex-direction: column;
    }
    article.card.contact_card {
        padding: inherit;
        width: 84vw;
        height: 71vh !important;
    }
    a.btn.sm.contact-cta-btn {
        position: relative;
        bottom: 1em;
    }
    /* rest of the pages */
    .header {
        display: none !important;
    }
    /* ============================================team */
    .team-cont {
        flex-direction: column;
        margin: 6em 0.5em 2em 0.5em;
    }
    .team-card {
        flex-direction: column;
    }
    .team-card-img img {
        width: 90vw;
    }
    .team-member-details {
        text-align: center !important;
    }
}

@media (min-width: 385px) and (max-width: 412px) {
    .homepage-header {
        height: 41vh;
    }
    .btn-fumigation {
        width: 53vw;
    }
}

@media (min-width: 413px) and (max-width: 414px) {
    .homepage-header {
        height: 41vh;
    }
    .btn-fumigation {
        width: 53vw;
    }
}

@media (min-width: 414px) and (max-width: 540px) {
    .homepage-header {
        height: 52vh;
    }
    .btn-fumigation {
        width: 53vw;
    }
}