.consultant-cont {
    display: flex;
    flex-direction: column;
    margin: 3em 3em 2em 3em;
    grid-gap: 1em;
}

.consultant-card {
    display: flex;
    flex-direction: row;
    grid-gap: 1em;
    margin-bottom: 2em;
}

.consultant-card-img {
    width: 25%;
}

.consultant-card-img img {
    width: 21vw;
}

.consultant-member-details {
    padding: 3px;
    width: 70vw;
    ;
}

.consultant-member-details h4 {
    font-family: 'Baskervville', serif !important;
    color: var(--p-blue);
    font-size: 20px !important;
}